<template>
    <div class="flex col-span-4 flex-col">
        <el-form
            ref="form"
            v-loading="$wait.is('modal.loading.*')"
            element-loading-spinner="el-custom-spinner"
            :model="formData"
            :rules="rules"
            label-position="top"
        >
            <el-form-item :label="$t('calendar.client_address')" prop="selectedAddress">
                <el-select v-model="formData.selectedAddress" :placeholder="$t('calendar.client_address')" filterable class="w-full mb-4">
                    <el-option
                        v-for="address in addresses"
                        :key="address.uuid"
                        :value="address.uuid"
                        :label="address.client + ' - ' + address.address"
                    />
                </el-select>
            </el-form-item>

            <div class="flex justify-between">
                <el-form-item :label="$t('calendar.date')" prop="endTime">
                    <el-date-picker
                        v-model="formData.startDate"
                        type="date"
                        :clearable="false"
                        :editable="false"
                        :picker-options="pickerOptions"
                        :placeholder="$t('calendar.start_date')"
                        format="dd.MM.yyyy"
                        @change="startDateChanged"
                    />
                </el-form-item>

                <el-form-item :label="$t('calendar.start_time')" prop="startTime">
                    <el-time-select
                        v-model="formData.startTime"
                        placeholder="Start time"
                        :clearable="false"
                        :picker-options="{
                            start: '00:00',
                            end: '23:45',
                            step: '00:15',
                        }"
                        @change="startTimeChanged"
                    />
                </el-form-item>
                <el-form-item :label="$t('calendar.end_time')" prop="endTime">
                    <el-time-select
                        v-model="formData.endTime"
                        placeholder="End time"
                        :clearable="false"
                        :picker-options="{
                            start: '00:00',
                            end: '23:45',
                            step: '00:15',
                            minTime: formData.startTime
                        }"
                    />
                </el-form-item>
            </div>
        </el-form>
        <el-button class="self-start mt-4" type="success" @click="search">
            Search
        </el-button>
    </div>
</template>
<script>
import Vue from 'vue';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    Form, FormItem, Input, Select, Option, OptionGroup, DatePicker, Switch, TimeSelect,
} from 'element-ui';



Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(TimeSelect);

export default {
    data() {
        return {
            formData: {
                startDate:       this.$dayjs(),
                startTime:       '12:00',
                endTime:         '14:00',
                chosenDate:      this.$dayjs(),
                selectedAddress: null,
            },
            addresses:     [],
            pickerOptions: {
                firstDayOfWeek: 1,
                disabledDate(date) {
                    return date.getTime() < this.$dayjs().subtract(1, 'day');
                },
            },
            rules: {
                selectedAddress: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                startTime:       [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                endTime:         [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    created() {
        this.getAddresses();
    },

    methods: {
        async getAddresses() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/addresses/clients_first`);
            this.addresses = data;
        },

        startDateChanged() {

        },

        async search() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            const startDate = this.$dayjs(this.formData.startDate);
            const startTime = this.$dayjs(this.formData.startTime, 'HH:mm');
            const endTime = this.$dayjs(this.formData.endTime, 'HH:mm');
            const duration = endTime.diff(startTime, 'minute');

            const selectedAddress = this.addresses.filter(address => address.uuid === this.formData.selectedAddress);

            this.$emit('search', {
                duration,
                chosenDate:  startDate.hour(startTime.hour()).minute(startTime.minute()).second(0),
                addressUuid: this.formData.selectedAddress,
                coords:      {
                    map_lat: selectedAddress[0].map_lat,
                    map_lng: selectedAddress[0].map_lng,
                },
            });
        },

        startTimeChanged() {
            if (this.formData.startTime >= this.formData.endTime) {
                this.formData.endTime = null;
            }
        },
    },
};
</script>
<style scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 130px !important;
}
</style>
